import styled, { css } from "styled-components";
import { colors } from "../../styles/global";
import { BREAKPOINTS, maxMedia } from "../../styles/utils/media-queries";
import { Paragraph, TitleSecondary } from "./Text";
import React from "react";

export const slantedTop = css`
  :before {
    background: inherit;
    content: "";
    display: block;
    height: 160px;
    top: -80px;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-3deg);
    transform-origin: 100%;
    z-index: -1;
  }
`;

export const slantedBottom = css`
  :after {
    background: inherit;
    bottom: 20px;
    content: "";
    display: block;
    height: 160px;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-3deg);
    transform-origin: 100%;
    z-index: -1;
  }
`;

export const SectionTitle = styled(TitleSecondary)`
  text-align: center;
`;

export const SectionSubTitle = styled.h1`
  margin-bottom: 2rem;
  line-height: 2.6rem;
  font-weight: 500;
  font-size: 2rem;
  color: ${colors.textSecondary};
  ${(props: any) => props.narrow && narrow};
`;

export const narrow = css`
  margin-left: auto;
  margin-right: auto;
  max-width: ${BREAKPOINTS.mobile}px;
`;

export const SectionParagraph = styled(Paragraph)<any>`
  text-align: center;
  color: ${colors.textSecondary};
  margin-bottom: 4rem;
  ${(props: any) => props.narrow && narrow};
`;

const Section = styled.section`
  position: relative;
  background: ${(props: any) => {
    if (props.secondary) return colors.backgroundSecondary;
    if (props.primary) return colors.primary;
  }};

  margin: ${(props: any) => {
    if (props.slantedTop && props.slantedBottom) return "6rem 0";
    if (props.slantedTop) return "6rem 0 0 0";
    if (props.slantedBottom) return "0 0 6rem 0";
    return "0";
  }};

  padding: ${(props: any) => {
    if (props.slantedTop && props.slantedBottom) return "0 0";
    if (props.slantedTop) return "0 0 4rem 0";
    if (props.slantedBottom) return "4rem 0 0 0";
    return `${props.paddingTop ? "4rem" : "0"} 0 ${props.paddingBottom ? "4rem" : "0"} 0`;
  }};

  ${SectionParagraph} {
    color: ${(props: any) => {
      if (props.primary) return "white";
    }};
  }
  ${SectionTitle} {
    color: ${(props: any) => {
      if (props.primary) return "white";
    }};
  }
  ${SectionSubTitle} {
    color: ${(props: any) => {
      if (props.primary) return "white";
    }};
  }

  ${maxMedia.mobile`
    :first-of-type {
      margin-top: 0rem;
    }
  `};
`;

const Slanted = styled.div<any>`
  position: relative;
  background: ${(props: any) => {
    if (props.secondary) return colors.backgroundSecondary;
    if (props.primary) return colors.primary;
  }};

  ${(props: any) => props.top && slantedTop};
  ${(props: any) => props.bottom && slantedBottom};
`;

const SectionMain = ({ children, ...rest }) => {
  return (
    <Section {...rest}>
      {rest.slantedTop && <Slanted top primary={rest.primary} />}
      {children}
      {rest.slantedBottom && <Slanted bottom primary={rest.primary} />}
    </Section>
  );
};

export default SectionMain;
