import React from "react";
import { GlobalStyle } from "../../styles/global";
import CookieNotification from "../elements/CookieNotification";
import Anchor from "./Anchor";
import Footer from "./Footer";
import Header from "./Header";
import { Main } from "./Main";

function Layout({ children, seamlessTop = false }) {
  return (
    <>
      <GlobalStyle />
      <Anchor id="top" />
      <Header />
      <div style={{ height: "4.5rem" }} />
      <Main style={{ marginTop: seamlessTop ? "-80px" : "0" }}>{children}</Main>
      <CookieNotification />
      <Footer />
    </>
  );
}

export default Layout;
