import styled from "styled-components";
import { colors } from "../../styles/global";

interface TextProps {
  inverted?: boolean;
}

export const Title = styled.div<TextProps>`
  font-size: 3.7rem;
  margin-bottom: 3rem;
  font-weight: 500;
  color: ${(props) => (props.inverted ? colors.textInverted : colors.text)};
`;

export const TitleSecondary = styled.div`
  font-size: 3rem;
  margin-bottom: 3rem;
  font-weight: 500;
  color: ${colors.textSecondary};
`;

export const Caption = styled.div`
  font-size: 1.6rem;
  line-height: 2.3rem;
  font-weight: 300;
  margin-bottom: 2rem;
`;

export const Overview = styled.div`
  color: ${colors.textSecondary};
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-weight: 400;
`;

export const Paragraph = styled.div`
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 1rem;
`;

export const Text = styled.div<TextProps>`
  white-space: initial;

  h1 {
    font-size: 3.2rem;
    line-height: 4.8rem;
    margin: 2rem 0 1rem 0;
    font-weight: 500;
    color: ${(props) => (props.inverted ? colors.textInverted : colors.text)};
  }

  h2 {
    font-size: 2.4rem;
    line-height: 3.2rem;
    margin: 2rem 0 1rem 0;
    font-weight: 500;
    color: ${colors.ipsred};
  }

  h3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
    margin: 1rem 0 0.5rem 0;
    font-weight: 500;
    color: ${colors.textSecondary};
  }

  p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 1rem;
  }

  strong {
    font-weight: bold;
  }

  li {
    font-size: 1.1rem;
    line-height: 1.8rem;
    margin-bottom: 1rem;
  }

  a {
    margin-left: 0.25rem;
    cursor: pointer;
    color: ${colors.primary};
  }
`;
