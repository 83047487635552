import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import Logo from "../../media/images/ips-logo.svg";
import { colors } from "../../styles/global";
import { maxMedia, minMedia } from "../../styles/utils/media-queries";
import { ButtonTransparent, IconButton } from "../elements/Button";
import { FlexContainer } from "../elements/Container";

const isAtTopStyles = css`
  box-shadow: none;
  background: transparent;
`;

const HeaderBackground = styled.header`
  z-index: 100;
  top: 0;
  position: fixed;
  left: 0;
  right: 0;
  background: ${colors.background};
  color: ${colors.textSecondary};
  transition: box-shadow 0.2s ease-in-out, background 0.2s ease-in-out;
  box-shadow: 1px 1px 12px rgba(0, 0, 0, 0.15);
  ${({ isAtTop }) => isAtTop && isAtTopStyles};

  height: 4.5rem; /* fix for safari bug */

  #mobile-nav-button {
    display: none;
  }

  ${maxMedia.mobile`
    #mobile-nav-button {
      display: flex;
    }
  `}
`;

const HeaderNavLink = styled(Link)`
  display: flex;
  font-size: 1.2rem;
  color: ${colors.textSecondary};
  opacity: 0.9;
  margin-right: 3rem;
  height: 100%;

  :focus {
    outline: none;
  }

  :hover {
    opacity: 1;
  }

  div {
    text-align: center;
    align-self: center;
  }
`;

const DesktopMenu = styled.div`
  display: flex;

  ${maxMedia.mobile`
    display: none;
  `}
`;

const MobileMenu = styled.div`
  background: ${colors.background};
  border-top: 1px solid ${colors.border};
  border-bottom: 1px solid ${colors.border};
  display: block;

  ${minMedia.mobile`
    display: none;
  `}

  ${HeaderNavLink} {
    margin: 0;
    padding: 0 1.4rem;
    height: 4rem;
    font-size: 1.3rem;

    :not(:last-of-type) {
      border-bottom: 1px solid rgb(255, 255, 255, 0.25);
    }
  }
`;

const headerQuery = graphql`
  query {
    strapiHeader {
      logo {
        file {
          publicURL
        }
      }
      links {
        ... on StrapiComponentInputLink {
          _xtypename
          id
          label
          url
        }
        ... on StrapiComponentInputButton {
          _xtypename
          id
          label
          outline
          primary
          size
          url
        }
      }
    }
  }
`;

const isBrowser = typeof window !== "undefined";

function Header() {
  const { strapiHeader } = useStaticQuery(headerQuery);
  const [menuVisible, toggleMenuVisible] = React.useState(true);
  const [isAtTop, setAtTop] = React.useState(isBrowser ? !window.pageYOffset : true);

  // check if the user has scrolled to the top of the page
  React.useEffect(() => {
    function onScroll() {
      setAtTop(!window.pageYOffset);
    }
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // fix for issue where header starts transparent at the middle of the page
  React.useEffect(() => {
    if (window.pageYOffset > 0) setAtTop(false);
  }, [isAtTop]);

  return (
    <HeaderBackground isAtTop={isAtTop}>
      <FlexContainer style={{ height: "100%" /* safari 13.0.4 fix */ }}>
        <div style={{ display: "flex" }}>
          <HeaderNavLink to="/#top">
            <img style={{ width: "80px", height: "4.5rem" /* ie 11 fix */ }} alt="logo" src={Logo} />
          </HeaderNavLink>
        </div>
        <DesktopMenu>
          {(strapiHeader.links || []).map((link) => {
            if (link._xtypename === "ComponentInputLink") {
              return (
                <HeaderNavLink key={link.url} to={link.url}>
                  <div>{link.label}</div>
                </HeaderNavLink>
              );
            }

            if (link._xtypename === "ComponentInputButton") {
              return (
                <Link key={link.url} to={link.url}>
                  <ButtonTransparent>{link.label}</ButtonTransparent>
                </Link>
              );
            }
            return null;
          })}
        </DesktopMenu>
        <IconButton id="mobile-nav-button" onClick={() => toggleMenuVisible(!menuVisible)}>
          <FontAwesomeIcon icon={faBars} />
          <span>menu</span>
        </IconButton>
      </FlexContainer>
      {menuVisible && (
        <MobileMenu>
          {(strapiHeader.links || []).map((link) => {
            return (
              <HeaderNavLink key={link.url} to={link.url} onClick={() => toggleMenuVisible(!menuVisible)}>
                <div>{link.label}</div>
              </HeaderNavLink>
            );
          })}
        </MobileMenu>
      )}
    </HeaderBackground>
  );
}

export default Header;
