import { css } from "styled-components";

export const BREAKPOINTS = {
  desktop: 1280,
  tablet: 991,
  mobile: 767,
  xss: 568,
  xsss: 288,
};

export const minMedia = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${BREAKPOINTS[label]}px) {
      ${(css as any)(...args)};
    }
  `;
  return acc;
}, {}) as any;

export const maxMedia = Object.keys(BREAKPOINTS).reduce((acc, label) => {
  acc[label] = (...args: any) => css`
    @media (max-width: ${BREAKPOINTS[label]}px) {
      ${(css as any)(...args)};
    }
  `;
  return acc;
}, {}) as any;
