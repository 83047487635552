import { createGlobalStyle } from "styled-components";
import { maxMedia } from "./utils/media-queries";
import reset from "./reset";

export const fonts = {
  text: '"Lato", "Roboto", "Helvetica Neue", sans-serif',
  code: "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
};

export const fontWeights = {
  light: "300",
  medium: "400",
  bold: "700",
};

export const border = {
  radius: "16",
  style: "1px solid",
};

export const boxShadow = [
  "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
  "0 2px 4px 0 rgba(60, 64, 67, .3), 0 6px 11px 5px rgba(60, 64, 67, .15)",
];

// textSecondary: "#36474f",
export const colors = {
  primary: "#c33339", // ipsred_dark
  background: "#ffffff",
  backgroundSecondary: "#f0f0f0",
  backgroundTertiary: "#fbfbfb",
  backgroundDark: "#000906",
  text: "#222",
  textSecondary: "#000000",
  textTertiary: "#444444",
  textInverted: "#dfe0e1", // Jonas light grey
  textDarkGrey: "#757477", // Jonas darg grey
  border: "#537a8c",
  borderSecondary: "#dadce0",
  borderTertiary: "#eceff1",
  green: "#34a853",
  ipsred_dark: "#a91c24",
  ipsred: "#c33339",

  negative: "#C33339",
  inputBorder: "#EDF2F7",
  inputBorderHover: "#0049B8",
};

export const GlobalStyle = createGlobalStyle`
  ${reset}

  html {
    height: 100%;
    font-size: 14px;
    background: ${colors.background};
    color: ${colors.text};
  }

  body {
    margin: 0;
    height: 100%;
    font-family: ${fonts.text};
    font-weight: ${fontWeights.medium};
    white-space: pre-wrap;
    text-rendering: optimizeLegibility;
  }

  code {
    font-family: ${fonts.code};
  }

  hr {
    border: none;
    border-top: 1px solid ${colors.borderSecondary};
  }

  ${maxMedia.tablet`
    html {
      font-size: 12px;
    }
  `}
`;

export const sizes = {
  mini: "mini",
  tiny: "tiny",
  small: "small",
  medium: "medium",
  large: "large",
  big: "big",
  huge: "huge",
  massive: "massive",
};
