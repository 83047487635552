import styled, { css } from "styled-components";

import { colors, fonts, fontWeights } from "../../styles/global";

const disabled = css`
  opacity: 0.5;

  :focus,
  :active,
  :hover {
    opacity: 0.5;
    filter: none;
  }
`;

const visibleHidden = css`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Button = styled.button`
  cursor: pointer;
  padding: 0.7rem 2.2rem;
  border-radius: 4px;
  letter-spacing: 2px;
  border: 0;
  background-color: ${colors.backgroundSecondary};
  line-height: 1.8rem;
  font-family: ${fonts.text};
  font-size: 0.9rem;
  font-weight: ${fontWeights.bold};
  margin: 8px 0;
  text-transform: uppercase;

  ${(props) => props.size === "small" && "padding: .2rem 1rem"};

  i,
  svg {
    font-size: 1.2rem;
    margin-right: 1rem;
  }

  :focus,
  :active,
  :hover {
    filter: brightness(0.9);
  }

  ${(props) => props.disabled && disabled}
`;

export const IconButton = styled(Button)`
  cursor: pointer;
  padding: 0.7rem 1.2rem;
  display: flex;

  i,
  svg {
    line-height: 1.8rem;
    align-self: center;
    margin: 0;
    font-size: 1.2rem;
  }

  span {
    ${visibleHidden};
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${colors.primary};
  color: #fff;

  :focus,
  :active,
  :hover {
    filter: brightness(1.1);
  }

  ${(props) => props.disabled && disabled}
`;

export const ButtonTransparent = styled(Button)`
  background: transparent;
  border: 1px solid ${colors.textSecondary};
  color: ${colors.textSecondary};
  opacity: 1;
  /* box-shadow: 1px 1px #888888; */

  :focus,
  :active,
  :hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.2);
  }
`;
