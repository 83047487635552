import { Link, useStaticQuery, graphql } from "gatsby";
import Cookies from "js-cookie";
import React from "react";
import styled from "styled-components";
import { colors } from "../../styles/global";
import { BREAKPOINTS, maxMedia } from "../../styles/utils/media-queries";
import { ButtonPrimary } from "./Button";

const CONSENT = "CONSENT";

const FixedBottom = styled.div`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 99999;
`;

const SnackbarMessage = styled.div`
  text-overflow: ellipsis;
  word-break: break-word;
  padding-bottom: 12px;
  font-size: 1.1rem;
`;

const SnackbarInner = styled.div`
  width: 100%;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
  color: ${colors.textInverted};
  background: ${colors.backgroundDark};
  flex-direction: column;
  padding: 16px 24px;
  ${SnackbarMessage} {
  }
`;

const SnackbarInputs = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    margin-left: 1rem;
  }

  a {
    padding: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: rgb(242, 242, 242, 0.8);
  }

  a:hover {
    color: rgb(242, 242, 242, 1);
    text-decoration: underline;
    text-decoration-style: dashed;
  }
`;

const Snackbar = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  max-width: ${BREAKPOINTS.xss}px;
  min-width: ${BREAKPOINTS.xsss}px;
  padding: 0 0 24px 24px;
  transition: transform 0.2s cubic-bezier(0.4, 0, 1, 1), -webkit-transform 0.2s cubic-bezier(0.4, 0, 1, 1);

  ${maxMedia.mobile`
    padding: 0;
    max-width: 100%;
    min-width: 100%;
    ${SnackbarInner} {
      border-radius: 0;
    }
  `}
`;

const cookieBannerQuery = graphql`
  query {
    strapiCookieBanner {
      buttonLabel
      message
      link {
        url
        label
      }
    }
  }
`;

function CookieNotification() {
  const [isVisible, setVisible] = React.useState(false);
  const { strapiCookieBanner } = useStaticQuery(cookieBannerQuery);

  const acceptCookies = () => {
    Cookies.set(CONSENT, true);
    setVisible(false);
  };

  React.useEffect(() => {
    const consent = Cookies.get(CONSENT);
    if (!consent) {
      setVisible(true);
    }
  }, []);

  if (!isVisible) return null;

  return (
    <FixedBottom>
      <Snackbar>
        <SnackbarInner>
          <SnackbarMessage>{strapiCookieBanner.message}</SnackbarMessage>
          <SnackbarInputs>
            <Link to={strapiCookieBanner.link.url}>{strapiCookieBanner.link.label}</Link>
            <ButtonPrimary onClick={acceptCookies} size="small">
              {strapiCookieBanner.buttonLabel}
            </ButtonPrimary>
          </SnackbarInputs>
        </SnackbarInner>
      </Snackbar>
    </FixedBottom>
  );
}

export default CookieNotification;
