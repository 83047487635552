import { faLinkedin, faYoutube, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import styled, { css } from "styled-components";
import { colors } from "../../styles/global";
import { maxMedia } from "../../styles/utils/media-queries";
import { Container } from "../elements/Container";

const visibleHidden = css`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const FooterBackground = styled.footer`
  color: ${colors.textInverted};
  background: ${colors.backgroundDark};
  padding: 1.2rem 0;
`;

const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
    margin-left: 1rem;
    font-size: 2.4rem;
  }

  a {
    cursor: pointer;
    color: ${colors.textInverted};
    opacity: 0.5;
    text-decoration: none;
  }

  span {
    ${visibleHidden};
  }

  a:hover {
    opacity: 1;
  }
`;

const FooterInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0;

  > * {
    margin-right: 4rem;
  }

  ${maxMedia.tablet`
    > * {
      margin-bottom: 2.4rem;
    }
  `}
`;

const InfoList = styled.div`
  min-width: 25%;

  h3 {
    font-weight: 600;
    opacity: 0.6;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  li {
    color: rgb(242, 242, 242, 0.5);
    padding: 0;
    line-height: 2rem;
  }

  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    min-width: 4rem;
  }

  a:hover {
    color: rgb(242, 242, 242, 1);
    text-decoration: underline;
    text-decoration-style: dashed;
  }

  ${maxMedia.tablet`
    font-size: 1.2rem;

    li {
      line-height: 40px;
    }
  `}
`;

const CopyrightNotice = styled.div`
  text-align: right;
  opacity: 0.6;
`;

const footerQuery = graphql`
  query {
    strapiFooter {
      footerLinks {
        links {
          label
          url
        }
        groupTitle
      }
      copyrightText
      email
      address
      youtubeURL
      linkedinURL
	  twitterURL
    }
  }
`;

function Footer() {
  const { strapiFooter } = useStaticQuery(footerQuery);

  return (
    <FooterBackground>
      <Container>
        <SocialMediaContainer>
          <a href={strapiFooter.youtubeURL}>
            <FontAwesomeIcon icon={faYoutube} />
            <span>youtube</span>
          </a>
          <a href={strapiFooter.linkedinURL}>
            <FontAwesomeIcon icon={faLinkedin} />
            <span>linkedin</span>
          </a>
		  <a href={strapiFooter.twitterURL}>
            <FontAwesomeIcon icon={faTwitter} />
            <span>twitter</span>
          </a>
        </SocialMediaContainer>
        <FooterInfoContainer>
          {strapiFooter.footerLinks.map((linkGroup) => (
            <InfoList key={linkGroup.groupTitle}>
              <h3>{linkGroup.groupTitle}</h3>
              <ul>
                {linkGroup.links.map((item, idx) => (
                  <li key={idx}>
                    <a href={item.url} aria-label={`learn more about ${item.label}`}>
                      {item.label}
                    </a>
                  </li>
                ))}
              </ul>
            </InfoList>
          ))}
          <InfoList>
            <h3>Contact</h3>
            <ul>
              <li>
                <a rel="noopener noreferrer" target="_blank" href={`mailto:${strapiFooter.email}`}>
                  {strapiFooter.email}
                </a>
              </li>
              <li>{strapiFooter.address}</li>
            </ul>
          </InfoList>
        </FooterInfoContainer>
        <InfoList>
          <ul>
            <li>
              <Link to={"/privacy"}>Privacy Policy</Link>
            </li>
          </ul>
        </InfoList>
        <CopyrightNotice>{strapiFooter.copyrightText.replace("{year}", new Date().getFullYear())}</CopyrightNotice>
      </Container>
    </FooterBackground>
  );
}

export default Footer;
