import styled from "styled-components";

import { maxMedia, BREAKPOINTS } from "../../styles/utils/media-queries";

export const Container = styled.div<any>`
  max-width: ${(props) => (props.narrow ? BREAKPOINTS.tablet - 10 : BREAKPOINTS.desktop - 10)}px;
  margin-left: auto;
  margin-right: auto;

  ${maxMedia.desktop`
    margin: 0 1.4rem;
  `}
`;

export const FlexContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;
